import React, { Fragment } from 'react';
import './App.scss'

const Header = React.lazy(() => import("components/Header"));
const Services = React.lazy(() => import("components/Services"));
const Platforms = React.lazy(() => import("components/Platforms"));
const Projects = React.lazy(() => import("components/Projects"));
const Clients = React.lazy(() => import("components/Clients"));
const Donations = React.lazy(() => import("components/Donations"));
const Contact = React.lazy(() => import("components/Contact"));
const Footer = React.lazy(() => import("components/Footer"));
class App extends React.Component {
  render() {
    return  (
      <Fragment>
        <div className='container'>
          <header className="header">
              <Header />
          </header>
          <main>
            <section  className="home" id="home">
              <h1>Desarrollo y consultoría e-commerce y digital</h1>
              <h2>Fábrica de desarrollo</h2>
            </section>
            <section  className="services" id="services">
              <Services />
            </section>
            <section  className="platforms">
              <Platforms />
            </section>
            <section  className="projects">
              <Projects />
            </section>
            <section  className="clients" id="clients">
              <Clients />
            </section>
            <section  className="contact" id="contact">
              <Contact />
            </section>
            <section  className="donations" id="donations">
              <Donations />
            </section>
          </main>
          <footer>
              <Footer />
          </footer>
        </div>
      </Fragment>
    );
  }
}
export default App;